<template>
  <div class="pad-3">
    <div class="bb-container bb-container--lista">
      <h1 class="header-1">{{ $t("cookiesPolicy.cookiesPolicy") }}</h1>
      <div class="post-entry">
        <h3>{{ $t("cookiesPolicy.cookiesUse") }}</h3>

        <h4>{{ $t("cookiesPolicy.cookiesUseInWebsite") }}</h4>

        <p>
          {{ $t("cookiesPolicy.cookiesUseInWebsiteText1") }}
        </p>
        <p>
          {{ $t("cookiesPolicy.cookiesUseInWebsiteText2") }}
        </p>

        <h4>{{ $t("cookiesPolicy.whatIsACookie") }}</h4>

        <p>
          {{ $t("cookiesPolicy.whatIsACookieText1") }}
        </p>

        <h4>{{ $t("cookiesPolicy.typesOfCookies") }}</h4>

        <p>
          {{ $t("cookiesPolicy.typesOfCookiesText1") }}
          <br />
        </p>
        <p>
          <strong>{{ $t("cookiesPolicy.typesOfCookiesText2") }}</strong>
        </p>
        <p>
          {{ $t("cookiesPolicy.typesOfCookiesText3") }}
        </p>

        <h4>
          {{ $t("cookiesPolicy.howToDisableCookies") }}
        </h4>

        <p>
          {{ $t("cookiesPolicy.howToDisableCookiesText1") }}
        </p>
        <p>
          {{ $t("cookiesPolicy.howToDisableCookiesText2") }} <a :href="$t('cookiesPolicy.howToDisableCookiesLink')">{{ $t("cookiesPolicy.howToDisableCookiesAqui") }}</a>.
        </p>
        <p>
          {{ $t("cookiesPolicy.howToDisableCookiesText3") }}
        </p>
        <p>
          <a href="#" class="cky-banner-element">{{ $t("cookiesPolicy.howToDisableCookiesButton") }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookies",
  metaInfo() {
    return {
      title: "Política de cookies de HelloBB",
      meta: [
        { name: "description", content: "Usamos cookies para que puedas usar nuestra web y app. Aquí tienes información sobre cómo lo hacemos."},
        { name: "robots", content: "noindex"}
      ]
    };
  },

  data() {
    return {};
  },
  computed: {},

  // Server-side only
  // This will be called by the server renderer automatically
  serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
  },

  // Client-side only
  mounted() {},

  methods: {},
};
</script>

<style scoped>
h3 {
  font-weight: bold;
}
</style>
